<template>
  <Prose :style="$viewport.isLessThan('tablet') ? 'font-size: 0.875em;' : ''">
    <MDCSlot />
  </Prose>
</template>

<script lang="ts" setup>
import { Prose } from '@crearis/ui'
import { useNuxtApp } from '#app'
const { $viewport } = useNuxtApp()
</script>
